.hoox-reason {
    padding: 32px 0 48px;
}

.hoox-reason__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    align-items: flex-start;
}

.hoox-reason__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hoox-reason__number-wrapper {
    background-color: var(--hoox-color-lilac);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 19px;
}

.hoox-reason__number {
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    font-style: italic;
}

.hoox-reason__title {
    margin-bottom: 16px;
    text-align: left;
}

.hoox-reason__description {
    text-align: left;
    line-height: 24px;
}

.hoox-reason__btn {
    margin-top: 24px;
}

@media (min-width: 1024px) {
    .hoox-reason__title {
        text-align: center;
        margin-bottom: 17px;
    }

    .hoox-reason__content {
        align-items: center;
        flex: 1;
        justify-content: center;
    }

    .hoox-reason__media {
        flex: 1;
    }

    .hoox-reason__wrapper {
        flex-direction: row-reverse;
        gap: 80px;
        align-items: center;
    }

    .hoox-reason {
        padding: 80px 0;
    }

    .hoox-reason__wrapper--reverse {
        flex-direction: row;
    }

    .hoox-reason__title, .hoox-reason__description {
        max-width: 364px;
    }

    .hoox-reason__btn {
        min-width: 244px;
    }

    .hoox-reason__description {
        text-align: center;
    }
}